
//Navigation bar functionality

liAbout.addEventListener('click', function (event) {
    scrollTo(0, sectionAbout.offsetTop);
});

liServices.addEventListener('click', function (event) {
    scrollTo(0, sectionServices.offsetTop);
});

liQuote.addEventListener('click', function (event) {
    scrollTo(0, sectionQuote.offsetTop);
});

liUnlimited.addEventListener('click', function (event) {
    scrollTo(0, sectionUnlimited.offsetTop);
});

liContact.addEventListener('click', function (event) {
    scrollTo(0, sectionSecret.offsetTop);
});


//Home button functionality
// const home = document.getElementById('home');

// home.addEventListener('click', function (event) {
//     scrollTo(0, 0);
// });


